export const buttonTexts = {
  es: {
    play: 'leer en español',
    pause: 'PAUSA',
    stop: 'DETENER',
    ttsProcessingInProgress:
      'Todavía estamos procesando el archivo de audio para esta pregunta. Regrese a esta pregunta después de un tiempo.',
    ttsNotSupported:
      'La reproducción de MP3 no es compatible con este navegador',
    playAll: 'Reproducir todo',
    playPassage: 'Reproducir el pasaje',
    playQuestion: 'Reproducir la pregunta',
  },
  en: {
    play: 'Read in english',
    pause: 'pause',
    stop: 'stop',
    playAll: 'Play All',
    playPassage: 'Play Passage',
    playQuestion: 'Play Question',
    ttsProcessingInProgress:
      'We are still processing the audio file for this question. Please return back to this question after some time.',
    ttsNotSupported: 'MP3 playback is not supported in this browser',
  },
}

export const languageDropdown = {
  es: 'Spanish',
  en: 'English',
}

export const stackedTranslationMap = {
  ES_EN: 'es-en',
  EN_ES: 'en-es',
  DISABLED: 'disabled',
}

export const stackedTranslationBoolToStringMap = {
  true: stackedTranslationMap.ES_EN,
  false: stackedTranslationMap.DISABLED,
}

export const stackedTranslationStringToBoolMap = {
  [stackedTranslationMap.ES_EN]: true,
  [stackedTranslationMap.DISABLED]: false,
}

export const stackedTranslationAllowedValues = [
  stackedTranslationMap.DISABLED,
  stackedTranslationMap.ES_EN,
]
export const stackedTranslationEnabledValues = [
  stackedTranslationMap.ES_EN,
  stackedTranslationMap.EN_ES,
]
